import { createStyles, Text, Container } from "@mantine/core";
import { IconCopyright } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    footer: {
        paddingBottom: theme.spacing.xl,
    },

    afterFooter: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,

        fontWeight: 300
    },
}));


export function FooterBlock() {
    const {classes} = useStyles();

    return (
        <footer className={classes.footer}>
            <Container className={classes.afterFooter}>
                <Text color="white" size="sm">
                    <IconCopyright size={18} style={{verticalAlign: 'middle', paddingBottom: 2}}/>
                    {' '}
                    {new Date().getFullYear().toString()}
                    {' '}
                    Intelligent Cloud Technologies OÜ <br/>
                </Text>
                <Text color="dimmed" size="xs" mt={'sm'}>
                    Aiandi tn 5/2-17, 12916, Tallinn, Estonia <br/>
                    RN: 16603605, VAT ID: EE102551279 <br/>
                </Text>

                <Text color="white" size="xs" mt={'sm'} component="span"
                      sx={{cursor: 'pointer'}}
                      onClick={event => {
                          displayPreferenceModal();
                          event.preventDefault();
                          return false;
                      }}>
                    Consent Preferences
                </Text>
            </Container>
        </footer>
    );
}