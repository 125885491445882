import { CSSObject } from "@mantine/styles/lib/tss/types";
import { MantineTheme } from "@mantine/core";

type BlockClass = "block" | "wrapper" | "title";

export function blockStyles(
    theme: MantineTheme
): Record<BlockClass, CSSObject> {
    return {
        block: {
            maxWidth: "100%",
        },

        wrapper: {
            paddingTop: theme.spacing.xxl,
            paddingBottom: theme.spacing.xxxl,
        },

        title: {
            marginBottom: theme.spacing.sm,

            fontSize: theme.fontSizes.xl,
            fontWeight: 300,

            textAlign: "center",

            [theme.fn.smallerThan("sm")]: {
                fontSize: theme.fontSizes.lg,
            },
        },
    };
}
