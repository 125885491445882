import {createStyles, Container, Text, Button, Group} from '@mantine/core';
import {MainActionsProps} from "@/blocks/CommonProps";
import {useVisibilityTrack} from "@/events";

const BREAKPOINT = '@media (max-width: 755px)';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        boxSizing: 'border-box',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,

        paddingTop: theme.spacing.xxl,
        paddingBottom: theme.spacing.xxxl,

        [BREAKPOINT]: {
            paddingTop: theme.spacing.lg,
            paddingBottom: theme.spacing.xxl,
        },
    },

    inner: {
        position: 'relative',
        paddingTop: theme.spacing.xxxl,
        paddingBottom: theme.spacing.xxxl,
        maxWidth: 900,

        [BREAKPOINT]: {
            paddingTop: theme.spacing.xxl,
            paddingBottom: theme.spacing.xxl,
        },
    },

    title: {
        fontSize: theme.fontSizes.xxxl,
        fontWeight: 700,
        lineHeight: 1,

        marginBottom: theme.spacing.xxl,

        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        textAlign: 'center',

        [BREAKPOINT]: {
            fontSize: theme.fontSizes.xxl,
        },
    },

    description: {
        textAlign: 'center',

        fontSize: theme.fontSizes.lg,
        fontWeight: 300,

        [BREAKPOINT]: {
            fontSize: theme.fontSizes.md,
            fontWeight: 400,
        },
    },

    controls: {
        marginTop: theme.spacing.xxl,
        marginBottom: theme.spacing.xl,

        justifyContent: 'center',
    },

    button: {
        fontSize: theme.fontSizes.md,
        height: theme.spacing.xxl,

        paddingLeft: theme.spacing.xxl,
        paddingRight: theme.spacing.xxl,

        [BREAKPOINT]: {
            flex: 1,
        },
    },
}));

export function HeroBlock({primaryAction}: MainActionsProps) {
    const {classes, theme} = useStyles();

    const blockRef = useVisibilityTrack('hero');

    return (
        <div ref={blockRef} className={classes.wrapper}>
            <Container className={classes.inner}>
                <h1 className={classes.title}>LinesQueue<Text style={{ verticalAlign: 'super' }} component={'sup'} color={'orange'} fw={400} fz={'md'}>beta</Text></h1>

                <Text className={classes.description}>
                    <Text component="span" color={theme.primaryColor}>LinesQueue</Text>
                    {' '}
                    is an affordable
                    {' '}
                    <Text component="span" color={theme.primaryColor}>serverless</Text>
                    {' '}
                    event&#8209;streaming service that lets you build robust data&#8209;processing&nbsp;apps for just
                    {' '}
                    <Text component="span" color={theme.primaryColor}>$0.1</Text>
                    {' '}
                    per stored GB&nbsp;per&nbsp;month with up to
                    {' '}
                    <Text component="span" color={theme.primaryColor}>99.999999999%</Text>
                    {' '}
                    annual&nbsp;durability!
                </Text>

                <Group className={classes.controls}>
                    <Button component={'a'} onClick={primaryAction} className={classes.button}>
                        SIGN UP
                    </Button>
                </Group>
            </Container>
        </div>
    );
}