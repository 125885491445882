import {
    createStyles,
    Title,
    Text,
    Button,
    Container,
    Center,
} from '@mantine/core';
import {blockStyles} from "@/styles/blockStyles";
import {CommonBlockProps, MainActionsProps} from "@/blocks/CommonProps";
import {useVisibilityTrack} from "@/events";

const useStyles = createStyles((theme) => ({
    ...blockStyles(theme),

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
        },
    },

    button: {
        fontSize: theme.fontSizes.md,

        height: theme.spacing.xxl,

        paddingLeft: theme.spacing.xxxl,
        paddingRight: theme.spacing.xxxl,

        marginTop: theme.spacing.xxl,
    },
}));

export function SubscriptionBlock({primaryAction, id, background = ''}: CommonBlockProps & MainActionsProps) {
    const {classes} = useStyles();

    const blockRef = useVisibilityTrack(id);

    return (
        <Container id={id} ref={blockRef} className={classes.block} bg={background}>
            <Container className={classes.wrapper}>
                <Title className={classes.title}>
                    Ready for a better streaming service?
                </Title>

                <Text color="dimmed" size="md" align="center">
                    Get started right now!
                </Text>

                <Center>
                    <Button component={'a'} onClick={primaryAction} className={classes.button}>
                        SIGN UP
                    </Button>
                </Center>

            </Container>
        </Container>
    );
}
