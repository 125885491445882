import { hydrate, render } from "react-dom";
import { App } from "@/App";
import { trackEvent } from "@/events";

const rootElement = document.getElementById("root");

trackEvent("page.open");

if (rootElement && rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}

trackEvent("page.render");
