import {createStyles, Header, Container, Group, Burger, Paper, Transition, Text, Button} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {MainActionsProps} from "@/blocks/CommonProps";
import {IconExternalLink} from "@tabler/icons-react";

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
        zIndex: 1,
    },

    dropdown: {
        position: 'absolute',
        top: HEADER_HEIGHT,
        left: 0,
        right: 0,
        zIndex: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopWidth: 0,
        overflow: 'hidden',

        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
    },

    links: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    actions: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    burger: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colors.dark[0],
        fontSize: theme.fontSizes.sm,
        height: '100%',

        '&:hover': {
            backgroundColor: theme.colors.dark[6],
        },

        [theme.fn.smallerThan('md')]: {
            borderRadius: 0,
            padding: theme.spacing.md,
        },
    },

    primaryButton: {
    },
}));

interface HeaderResponsiveProps extends MainActionsProps {
    links: { link: string; label: string; external?: boolean }[];
}

export function HeaderBlock({primaryAction, links}: HeaderResponsiveProps) {
    const [opened, {toggle, close}] = useDisclosure(false);
    const {classes} = useStyles();

    const items = links.map((link) => (
        <a
            key={link.label}
            href={link.link}
            className={classes.link}
            onClick={close}
        >
            {link.label}

            {link.external && (<IconExternalLink color={'white'} stroke={1.5}
                                                 style={{ verticalAlign: 'bottom', height: '1rem'}}/>)}
        </a>
    ));

    return (
        <Header height={HEADER_HEIGHT} className={classes.root} withBorder={false}>
            <Container className={classes.header}>
                <Text color={'white'}>LinesQueue <Text component={'sup'} color={'orange'} fz={'xs'}>beta</Text></Text>

                <Group spacing={5} className={classes.links}>
                    {items}
                </Group>

                <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm"/>

                <Transition transition="pop-top-right" duration={200} mounted={opened}>
                    {(styles) => (
                        <Paper className={classes.dropdown} style={styles} radius={0}>
                            {items}
                        </Paper>
                    )}
                </Transition>

                <Group spacing={10} className={classes.actions}>
                    <Button component={'a'} onClick={primaryAction} className={classes.primaryButton}>
                        SIGN UP
                    </Button>
                </Group>


            </Container>
        </Header>
    );
}