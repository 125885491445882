import {
    createStyles,
    Text,
    SimpleGrid, Group, Paper, Container, Title,
} from '@mantine/core';
import {blockStyles} from "@/styles/blockStyles";
import {CommonBlockProps} from "@/blocks/CommonProps";
import {useVisibilityTrack} from "@/events";
import {
    IconCoin,
    IconCoinOff, IconCoins
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    ...blockStyles(theme),

    root: {
        padding: theme.spacing.xl,
    },

    icon: {},
}));

const icons = {
    free: IconCoinOff,
    flat: IconCoin,
    raise: IconCoins,
};

const data = [
    {
        title: 'Free Tier',
        price: '$0',
        details: <>First <Text component={'span'} c="white">1GB</Text> is always free.</>,
        icon: 'free'
    },
    {
        title: 'Flat Fee',
        price: '$10',
        details: <>With <Text component={'span'} c="white">100GB</Text> of storage capacity.</>,
        icon: 'flat'
    },
    {title: 'Pay as You Go', price: '$0.1 per GB', details: 'Scales as you use.', icon: 'raise'},
] as const;

export function PricingBlock({id, background = ''}: CommonBlockProps) {
    const {classes, theme} = useStyles();
    const blockRef = useVisibilityTrack(id);

    const stats = data.map((stat) => {
        const Icon = icons[stat.icon];

        return (
            <Paper withBorder variant={''} p="md" radius="md" key={stat.title}>
                <Group style={{justifyContent: 'space-between'}}>
                    <Text size="md" fw={400} c="orange">
                        {stat.title}
                    </Text>

                    <Icon className={classes.icon} color={'white'} size="2rem" stroke={1.5}/>
                </Group>


                <Text size="md" fw={400} c="white">
                    {stat.price}
                </Text>

                <Text size="sm" c="dimmed" mt={7}>
                    {stat.details}
                </Text>
            </Paper>
        );
    });
    return (

        <Container id={id} ref={blockRef} className={classes.block} bg={background}>
            <Container className={classes.wrapper}>
                <Title className={classes.title}>
                    Pay only for the
                    {' '}
                    <Text component="span" color={'orange'}> storage used</Text>
                </Title>

                <Text color="dimmed" size="sm" align="center">
                    There are
                    {' '}
                    <Text component="span" color={'orange'}>no fees</Text>
                    {' '}
                    charged for egress or API requests.
                </Text>

                <SimpleGrid pt={theme.spacing.xxl} breakpoints={[
                    {minWidth: 0, cols: 1},
                    {minWidth: 810, cols: 3}
                ]}>{stats}</SimpleGrid>
            </Container>
        </Container>
    );
}
