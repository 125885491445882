import { Global } from "@mantine/core";

import fontWeight100 from "@/fonts/greycliffcf-thin-webfont.woff2";
import fontWeight200 from "@/fonts/greycliffcf-extralight-webfont.woff2";
import fontWeight300 from "@/fonts/greycliffcf-light-webfont.woff2";
import fontWeight400 from "@/fonts/greycliffcf-regular-webfont.woff2";
import fontWeight500 from "@/fonts/greycliffcf-medium-webfont.woff2";
import fontWeight600 from "@/fonts/greycliffcf-demibold-webfont.woff2";
import fontWeight700 from "@/fonts/greycliffcf-bold-webfont.woff2";
import fontWeight800 from "@/fonts/greycliffcf-extrabold-webfont.woff2";
import fontWeight900 from "@/fonts/greycliffcf-heavy-webfont.woff2";

export function GlobalStyles() {
    return (
        <Global
            styles={[
                {
                    "@font-face": {
                        fontFamily: "Greycliff CF",
                        src: `url('${fontWeight100}') format("woff2")`,
                        fontWeight: 100,
                        fontStyle: "normal",
                    },
                },
                {
                    "@font-face": {
                        fontFamily: "Greycliff CF",
                        src: `url('${fontWeight200}') format("woff2")`,
                        fontWeight: 200,
                        fontStyle: "normal",
                    },
                },
                {
                    "@font-face": {
                        fontFamily: "Greycliff CF",
                        src: `url('${fontWeight300}') format("woff2")`,
                        fontWeight: 300,
                        fontStyle: "normal",
                    },
                },
                {
                    "@font-face": {
                        fontFamily: "Greycliff CF",
                        src: `url('${fontWeight400}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: "normal",
                    },
                },
                {
                    "@font-face": {
                        fontFamily: "Greycliff CF",
                        src: `url('${fontWeight500}') format("woff2")`,
                        fontWeight: 500,
                        fontStyle: "normal",
                    },
                },
                {
                    "@font-face": {
                        fontFamily: "Greycliff CF",
                        src: `url('${fontWeight600}') format("woff2")`,
                        fontWeight: 600,
                        fontStyle: "normal",
                    },
                },
                {
                    "@font-face": {
                        fontFamily: "Greycliff CF",
                        src: `url('${fontWeight700}') format("woff2")`,
                        fontWeight: 700,
                        fontStyle: "normal",
                    },
                },
                {
                    "@font-face": {
                        fontFamily: "Greycliff CF",
                        src: `url('${fontWeight800}') format("woff2")`,
                        fontWeight: 800,
                        fontStyle: "normal",
                    },
                },
                {
                    "@font-face": {
                        fontFamily: "Greycliff CF",
                        src: `url('${fontWeight900}') format("woff2")`,
                        fontWeight: 900,
                        fontStyle: "normal",
                    },
                },
            ]}
        />
    );
}
