import {Center, Container, createStyles, Text, Timeline, Title,} from '@mantine/core';
import {Prism} from '@mantine/prism';
import {IconCheck, IconExternalLink} from "@tabler/icons-react";
import {blockStyles} from "@/styles/blockStyles";
import {CommonBlockProps} from "@/blocks/CommonProps";
import {useVisibilityTrack} from "@/events";


const useStyles = createStyles((theme) => ({
    ...blockStyles(theme),

    description: {
        textAlign: 'center',
        fontSize: theme.fontSizes.sm,
    },

    comment: {
        textAlign: 'center',
        fontSize: theme.fontSizes.sm,
        lineHeight: 2,
    },
}));


export function DemoBlock({id, background = ''}: CommonBlockProps) {
    const {classes} = useStyles();
    const blockRef = useVisibilityTrack(id);

    const now = Date.now() / 1000 | 0;

    const events = [
        `{"event": "session_start", "timestamp": ${now}, "data": { "session_id": "1d051566" } }`,
        `{"event": "input_focus", "timestamp": ${now + 60}, "data": { "session_id": "1d051566", "target_input": "email" } }`,
        `{"event": "form_submit", "timestamp": ${now + 120}, "data": { "session_id": "1d051566", "form_data": "email=b.spoonfighter@foo.bar" } }`,
        `{"event": "session_end", "timestamp": ${now + 300}, "data": { "session_id": "1d051566" } }`,
    ];

    const landingDemoKey =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyIvdG9rZW4iOiJjMDA1NzFiNS0yYTc3LTQzNjAtOGU3NS0yY2M2ZmI0Njc2ZWYiLCIvdGVuYW50IjoiMCIsIi9yb2xlIjoiVGVuYW50IiwiaXNzIjoiUHJvZHVjdGlvbiIsImF1ZCI6Imh0dHBzOi8vYXBwLmxpbmVzcXVldWUuY29tLyJ9.VCOpDT2U28uqIwy4JshRLtbd5_qYsbVKCdn6SeKV0Js';

    const queueName = "demo." + Math.random().toString(36).substr(2);

    const appendCode = `
echo -e '${events.join('\\n')}' |
    curl 'https://app.linesqueue.com/v1/queues/append_lines?queue_name=${queueName}' --data-binary "@-" \\
      --header "Authorization: Bearer ${landingDemoKey}"
`;

    const readCode = `
curl 'https://app.linesqueue.com/v1/queues/read_lines?queue_name=${queueName}' \\
    --header "Authorization: Bearer ${landingDemoKey}"
`;
    const readOutput = events.join('\n');


    const readSegmentCode = `
curl 'https://app.linesqueue.com/v1/queues/read_lines?queue_name=${queueName}&start_line=1&lines_count=2' \\
    --header "Authorization: Bearer ${landingDemoKey}"
`;
    const readSegmentOutput = events.slice(1, 3).join('\n');

    return (
        <Container id={id} ref={blockRef} className={classes.block} bg={background}>
            <Container className={classes.wrapper}>
                <Title className={classes.title}>Try the demo!</Title>

                <Container p={0}>
                    <Text className={classes.description}>
                        With the ready-to-use
                        {' '}
                        <Text component="span" color={'orange'}>bash</Text>
                        {' '}
                        snippets below.
                    </Text>
                </Container>

                <Center mt={60}>
                    <Timeline color={'yellow'} active={3} bulletSize={32} lineWidth={1} style={{overflow: 'hidden'}}>

                        <Timeline.Item bullet={<IconCheck size={18} stroke={3}/>}
                                       title={<Text ml={10} size={18}>Send some line-separated records</Text>}>

                            <Prism ml={10} mt={10} language="bash">
                                {appendCode}
                            </Prism>

                        </Timeline.Item>

                        <Timeline.Item bullet={<IconCheck size={18} stroke={3}/>}
                                       title={<Text ml={10} size={18}>Read the entire queue</Text>}>

                            <Prism ml={10} mt={10} language="bash">
                                {readCode}
                            </Prism>

                            <Text ml={10} size="xs" mt={4} mb={2}>Example output:</Text>

                            <Prism ml={10} noCopy language={'markdown'}>
                                {readOutput}
                            </Prism>
                        </Timeline.Item>

                        <Timeline.Item bullet={<IconCheck size={18} stroke={3}/>}
                                       title={<Text ml={10} size={18}>Or just selected segment</Text>}>

                            <Prism ml={10} mt={10} language="bash">
                                {readSegmentCode}
                            </Prism>

                            <Text ml={10} size="xs" mt={4} mb={2}>Example output:</Text>

                            <Prism ml={10} noCopy language={'markdown'}>
                                {readSegmentOutput}
                            </Prism>
                        </Timeline.Item>

                    </Timeline>

                </Center>

                <Container p={0} mt={'xl'}>
                    <Text className={classes.comment}>
                        Want to know more about
                        {' '}
                        <Text component="span" color={'orange'}>LinesQueue</Text>?<br/>
                        {' '}
                        Check out the
                        {' '}
                        <Text component={'a'}
                              color={'orange'}
                              target={'_blank'}
                              href={'https://docs.linesqueue.com/'}>
                            documentation
                            <IconExternalLink color={'orange'} size={16} stroke={1.5}
                                              style={{verticalAlign: 'middle', paddingBottom: 2}}/>
                        </Text>!
                    </Text>
                </Container>
            </Container>
        </Container>
    );
}
