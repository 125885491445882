
export const privacyPolicyUrls = 'https://app.termly.io/document/privacy-policy/899c27d7-c471-451a-bb58-237aaa5762af';

export const auth0Domain = 'auth.linesqueue.com'
export const auth0ClientId = 's50V6ikyHz4wlpZmA5iM3RFo87WYzjoY';

export const eventsWriteToken = process.env.NODE_ENV === 'production'
    ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyIvdG9rZW4iOiIwOWJjY2IzYy05ZDZhLTQ0YTEtODU0My03ZTFjMmU0Njc1MGYiLCIvdGVuYW50IjoiMCIsIi9yb2xlIjoiVGVuYW50IiwiaXNzIjoiUHJvZHVjdGlvbiIsImF1ZCI6Imh0dHBzOi8vYXBwLmxpbmVzcXVldWUuY29tLyJ9.JpZLZ9Ql6Pj3a51MEwbGevazgERxHoJnhjiWEZMFG4A'
    : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyIvdG9rZW4iOiIyZDQwNjk3MS00MDg1LTQxY2UtYjU5NC0wMWEwZjBjZGVkNjIiLCIvdGVuYW50IjoiMCIsIi9yb2xlIjoiVGVuYW50IiwiaXNzIjoiUHJvZHVjdGlvbiIsImF1ZCI6Imh0dHBzOi8vYXBwLmxpbmVzcXVldWUuY29tLyJ9.Coqzo86E8BQVEmIHeYwVVYBtSVk7onelAtcd269ZRqg';

export const eventsQueueName = process.env.NODE_ENV === 'production'
    ? 'landing.prod.events'
    : 'landing.dev.events';