import {
    ThemeIcon,
    Text,
    Title,
    Container,
    SimpleGrid,
    createStyles,
} from '@mantine/core';
import {Icon} from '@tabler/icons-react';
import {ReactNode} from "react";
import {MantineColor} from "@mantine/styles";
import {blockStyles} from "@/styles/blockStyles";
import {CommonBlockProps} from "@/blocks/CommonProps";
import {useVisibilityTrack} from "@/events";


interface FeatureProps {
    icon: Icon;
    title: ReactNode;
    description: ReactNode;
    color: MantineColor
}

interface FeaturesGridProps extends CommonBlockProps {
    title: ReactNode;
    data: FeatureProps[];
}

const useStyles = createStyles((theme) => ({
    ...blockStyles(theme),

    itemIcon: {
        padding: theme.spacing.xxs,
        marginRight: theme.spacing.md,
    },

    itemText: {
        marginTop: 0,
        marginBottom: theme.spacing.xxs
    },
}));


export function Feature({icon: Icon, title, description, color}: FeatureProps) {
    const {classes} = useStyles();

    return (
        <div style={{display: 'flex'}}>
            <ThemeIcon variant="light" color={color} size={40} radius={40} className={classes.itemIcon}>
                <Icon size={24} stroke={1.5}/>
            </ThemeIcon>

            <div>
                <Text color={color} className={classes.itemText}>
                    {title}
                </Text>
                <Text size="sm" color="dimmed" style={{lineHeight: 1.6}}>
                    {description}
                </Text>
            </div>
        </div>
    );
}

export function FeaturesBlock({id, title, data, background = ''}: FeaturesGridProps) {
    const {classes, theme} = useStyles();

    const blockRef = useVisibilityTrack(id);

    const features = data.map((feature, index) => <Feature {...feature} key={index}/>);

    return (
        <Container id={id} ref={blockRef} className={classes.block} bg={background}>
            <Container className={classes.wrapper}>
                <Title className={classes.title}>{title}</Title>

                <SimpleGrid
                    mt={60}
                    cols={2}
                    spacing={theme.spacing.xxl}
                    breakpoints={[
                        {maxWidth: 980, cols: 2, spacing: 'xl'},
                        {maxWidth: 755, cols: 1, spacing: 'xl'},
                    ]}
                >
                    {features}
                </SimpleGrid>
            </Container>
        </Container>
    );
}