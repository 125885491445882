import {v4 as uuid} from 'uuid';
import {eventsQueueName, eventsWriteToken} from "@/config";
import {useEffect, useRef} from "react";

const eventsToTrack: string[] = [];
let submitCallbacks: (() => void)[] = [];

async function flushEventsQueue() {
    while (eventsToTrack.length > 0) {
        await flushEvents();

        const callbacks = submitCallbacks;

        submitCallbacks = [];

        for (let callback of callbacks) {
            callback.call(null);
        }
    }

    setTimeout(flushEventsQueue, 1000);
}

setTimeout(flushEventsQueue, 1000);

async function flushEvents(): Promise<void> {
    const promise = fetch('https://app.linesqueue.com/v1/queues/append_lines?queue_name=' + eventsQueueName, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${eventsWriteToken}`
        },

        body: eventsToTrack.join('\n')
    });

    eventsToTrack.length = 0;

    await promise;
}


let userId = localStorage.getItem('user-id');
if (userId == null) {
    localStorage.setItem('user-id',
        userId = uuid())
}

const pageId = uuid();


export function trackEvent(type: string, data?: object) {
    console.log('trackEvent', type, data);

    eventsToTrack.push(JSON.stringify({
        'specversion': '1.0',
        'source': 'https://linesqueue.com',
        'id': uuid(),
        'type': `com.linesqueue.${type}`,
        'data': {
            ...data,

            'env': process.env.NODE_ENV,
            'page': pageId,
            'query': window.location.search,
            'hash': window.location.hash
        },
        'time': new Date().toISOString(),
        'subject': userId
    }));
}

export function submitEvent(type: string, data?: object): Promise<void> {
    trackEvent(type, data);

    return new Promise(res => submitCallbacks.push(res))
}

export function useVisibilityTrack(block: string) {
    const blockRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio > 0) {
                trackEvent('ui.block.visible', {block})
            }
        });

        observer.observe(blockRef.current!);

        return () => {
            observer.disconnect();
        };
    });

    return blockRef;
}

