import {
    createStyles,
    Paper,
    Title,
    Text,
    TextInput,
    Button,
    Container,
    Group,
    Center,
    Textarea, Notification,
} from '@mantine/core';
import {useForm} from '@mantine/form';
import {blockStyles} from "@/styles/blockStyles";
import {CommonBlockProps} from "@/blocks/CommonProps";
import {privacyPolicyUrls} from "@/config";
import {IconCheck, IconExternalLink} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";
import {submitEvent, useVisibilityTrack} from "@/events";

const useStyles = createStyles((theme) => ({
    ...blockStyles(theme),

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
        },
    },
}));

export function FeedbackBlock({id, background = ''}: CommonBlockProps) {
    const {classes} = useStyles();

    const blockRef = useVisibilityTrack(id);

    return (
        <Container id={id} ref={blockRef} className={classes.block} bg={background}>
            <Container className={classes.wrapper}>
                <Title className={classes.title}>
                    Our goal is to help you build better data apps!
                </Title>

                <Text color="dimmed" size="sm" align="center">
                    Please feel free to submit us any issues you’ll have!
                </Text>

                <LocalForm/>
            </Container>
        </Container>
    );
}

function LocalForm() {
    const {classes} = useStyles();

    const [loaderShown, loaderShownHandle] = useDisclosure(false);
    const [formApplied, formAppliedHandle] = useDisclosure(true);

    const form = useForm({
        initialValues: {
            email: '',
            message: '',
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) || value?.length === 0 ? null : 'Invalid email'),
            message: (value) => (value.length > 0 ? null : 'Message is empty')
        },
    });

    async function submitForm(values: object) {
        formAppliedHandle.close();
        loaderShownHandle.open();

        await submitEvent('form.feedback', values);

        formAppliedHandle.open();
    }

    function resetFormState() {
        loaderShownHandle.close();
        form.reset();
    }

    return (
        <Center>
            <Paper component={'form'} shadow="md" w={540} p={30} radius="md" mt="xl"
                   onSubmit={form.onSubmit(submitForm)}>

                {loaderShown && <>
                    <Notification loading={!formApplied}
                                  withCloseButton={formApplied}
                                  icon={<IconCheck size={18}/>}
                                  onClose={resetFormState}
                                  p={'md'}
                                  title="Thank you!">
                        We appreciate your feedback!
                    </Notification>
                </>}

                {!loaderShown && <>
                    <TextInput styles={{label: {marginBottom: 5}}}
                               label="Your email"
                               placeholder="But it is not required"
                               name="email"
                               variant="filled"
                               {...form.getInputProps('email')}
                    />

                    <Textarea required
                              mt="md"
                              styles={{label: {marginBottom: 5}}}
                              label="Message"
                              placeholder="Anything we should know"
                              maxRows={5}
                              minRows={3}
                              autosize
                              name="message"
                              variant="filled"
                              {...form.getInputProps('message')}
                    />

                    <Group className={classes.controls} mt="xl" position="apart">
                        <Text className={classes.control} size={'xs'} w={280}>
                            If you enter your email address, you agree to the
                            {' '}
                            <Text component={'a'}
                                  color={'white'}
                                  target={'_blank'}
                                  href={privacyPolicyUrls}>
                                Privacy Policy
                                <IconExternalLink color={'white'} size={16} stroke={1.5}
                                                  style={{verticalAlign: 'middle', paddingBottom: 2}}/>
                            </Text>.
                        </Text>

                        <Button className={classes.control} type="submit" size="sm">
                            SEND MESSAGE
                        </Button>
                    </Group>
                </>}
            </Paper>
        </Center>
    )
}