import {MantineProvider, Text} from "@mantine/core";
import {HeroBlock} from "@/blocks/HeroBlock";
import {FeaturesBlock} from "@/blocks/FeaturesBlock";
import {
    IconCloud,
    IconDatabase, IconFreeRights,
    IconRefresh,
    IconTopologyComplex,
    IconWindowMaximize
} from "@tabler/icons-react";
import {DemoBlock} from "@/blocks/DemoBlock";
import {HeaderBlock} from "@/blocks/HeaderBlock";
import {SubscriptionBlock} from "@/blocks/SubscribtionBlock";
import {FooterBlock} from "@/blocks/FooterBlock";
import {MouseEvent, StrictMode} from "react";
import {GlobalStyles} from "@/styles/GlobalStyles";
import {FeedbackBlock} from "@/blocks/FeedbackBlock";
import {MainActionsProps} from "@/blocks/CommonProps";
import auth0 from 'auth0-js';
import {auth0ClientId, auth0Domain} from "@/config";
import {PricingBlock} from "@/blocks/PricingBlock";

export function App() {
    const challengesTitle = (<>
        Common challenges of running a data app
    </>);

    const challenges = [
        {
            icon: IconTopologyComplex,
            title: 'On-premises',
            description: 'Replicate your data three times and ensure failover for all components.',
            color: 'red'
        },
        {
            icon: IconCloud,
            title: 'In the cloud',
            description: 'Break through the complex pricing plans and uncomfortable limits.',
            color: 'red'
        },
    ];

    const featuresTitle = (<>
        With <Text component="span" color={'orange'}>LinesQueue</Text> development&nbsp;gets&nbsp;easier!
    </>)

    const features = [
        {
            icon: IconRefresh,
            title: 'Fast Iterations',
            description: (<>
                <Text color={'white'}>
                    Feel free to make mistakes, lose data, or change the storage scheme and processing logic.
                </Text>
                <br/>
                The strict ordering of the LinesQueue records allows you to unambiguously restore data
                stream "history" and process it again.
            </>),
            color: 'green'
        },
        {
            icon: IconFreeRights,
            title: 'Free Data Access',
            description: (<>
                <Text color={'white'}>
                    You won't have to pay a penny for the daily processing of your dataset!
                </Text>
                <br/>
                LinesQueue API calls are free of charge. Also, traffic is free if you don’t need guaranteed bandwidth.
            </>),
            color: 'green'
        },
        {
            icon: IconDatabase,
            title: 'Solid Durability',
            description: (<>
                <Text color={'white'}>
                    You don't have to maintain complex deployments and accumulate expensive state snapshot history.
                </Text>
                <br/>
                LinesQueue guarantees 99.999999999% of annual durability, which is more than 100 years without losses,
                even for a billion files.
            </>),
            color: 'green'
        },
        {
            icon: IconWindowMaximize,
            title: 'Simple Scaling',
            description: (<>
                <Text color={'white'}>
                    Distribute data processing without complex tricks and additional coordination.
                </Text>
                <br/>
                LinesQueue numbers each record, making data distribution easier in each processing unit.
            </>),
            color: 'green'
        },
    ];

    const links = [
        {link: "#features", label: "FEATURES"},
        {link: "#try", label: "TRY"},
        {link: "#pricing", label: "PRICING"},
        {link: "#support", label: "SUPPORT"},
        {link: "https://docs.linesqueue.com/", label: "DOCS", external: true},
    ]

    const webAuth = new auth0.WebAuth({
        domain: auth0Domain,
        clientID: auth0ClientId,
        audience: 'https://console.linesqueue.com/api/v1',
        redirectUri: 'https://console.linesqueue.com/'
    });

    const url =  webAuth.client.buildAuthorizeUrl({
        responseType: 'token',
        redirectUri: 'https://console.linesqueue.com/',
        nonce: Math.random().toString(32).slice(2),
    })

    function handleSignUp(event: MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();

        gtagReportConversion(() => {
            window.location.href = url + "&screen_hint=signup";
        });
    }

    const actions: MainActionsProps = {
        primaryAction: handleSignUp,
    };

    return (
        <StrictMode>
            <MantineProvider withGlobalStyles withNormalizeCSS theme={{
                colorScheme: 'dark',
                primaryColor: 'orange',

                fontFamily: 'Greycliff CF, sans-serif',
                fontFamilyMonospace: 'Monaco, Courier, monospace',
                headings: {
                    fontFamily: 'Greycliff CF, sans-serif',
                },

                spacing: {
                    xxs: "0.3125rem",
                    xs: "0.625rem",
                    sm: "0.75rem",
                    md: "1rem",
                    lg: "1.25rem",
                    xl: "1.5rem",
                    xxl: "3rem",
                    xxxl: "6rem"
                },

                fontSizes: {
                    xs: "0.875rem",
                    sm: "1.125rem",
                    md: "1.5rem",
                    lg: "1.75rem",
                    xl: "2.25rem",
                    xxl: "3.5rem",
                    xxxl: "7.5rem",
                }
            }}>
                <GlobalStyles/>

                <HeaderBlock links={links} {...actions}/>
                <HeroBlock {...actions}/>
                <FeaturesBlock id="challenges" title={challengesTitle} data={challenges}/>
                <FeaturesBlock id="features" title={featuresTitle} background={'dark'} data={features}/>
                <DemoBlock id="try"/>
                <SubscriptionBlock id="subscribe" background={'dark'} {...actions}/>
                <PricingBlock id={'pricing'} />
                <FeedbackBlock id="support" background={'dark'} />
                <FooterBlock/>
            </MantineProvider>
        </StrictMode>
    );
}
